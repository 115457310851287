<template>
  <svg
    width="24"
    height="38"
    viewBox="0 0 24 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.239 13.5414C16.9939 14.4692 18.91 15.4963 20.6016 16.7079C22.8584 14.0576 23.0326 10.6292 22.8029 8.28465C20.8506 10.6305 17.9848 12.3892 14.239 13.5414Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.570881 37.7236C2.53733 35.0165 5.10766 34.3868 9.62379 33.2785C11.3683 32.8514 13.3464 32.3662 15.736 31.6643C21.3077 30.0268 22.7335 26.8836 23.0857 25.1559C23.5528 22.8785 22.7373 20.4888 21.0096 19.0643C17.247 15.9636 13.5477 14.7404 8.0354 13.5701L6.85604 13.321L8.05217 13.1727C14.7631 12.3417 19.4509 10.1688 21.9838 6.71071C23.887 4.11201 24.0109 1.46168 23.9528 0.29007C22.4689 2.61136 19.518 3.31459 14.8483 4.42813C12.9115 4.89136 10.7167 5.41394 8.20056 6.1533C2.63024 7.78942 1.20443 10.9326 0.850881 12.6617C0.385075 14.9378 1.20056 17.3288 2.92701 18.752C6.68959 21.8527 10.3902 23.0746 15.9025 24.2462L17.0819 24.4965L15.8844 24.6436C12.4573 25.0681 9.17346 25.7985 6.63798 26.6978C3.43282 27.8359 1.40056 29.2114 0.597978 30.7881C-0.549119 33.0385 0.249591 36.5468 0.570881 37.7236Z"
      fill="white"
    />
  </svg>
</template>
