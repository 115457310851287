<template>
  <div class="appContainer">
    <div class="main">
      <Header :isHome="isHome" />
      <router-view />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
'use strict';

// Imports.
import router from '/src/router';
import { mapState } from 'vuex';

// Component imports.
import Header from '/src/pages/layout/Header.vue';
import Footer from '/src/pages/layout/Footer.vue';

// Set up the default application component.

export default {
  components: {
    Header
    // Footer
  },

  computed: {
    ...mapState({
      alert: state => Object.assign({}, state.alert)
    }),
    isHome() {
      return router.currentRoute.value.name === 'Home';
    }
  },

  mounted() {
    this.$store.$toasts = this.$toasts;
  },

  watch: {
    alert: {
      deep: true,
      handler: function(newAlert) {
        let message = newAlert.message;
        if (this.lastMessage === message) {
          // TODO remove this once alert hijacking is removed
          return;
        }
        let type = newAlert.type;
        let duration = newAlert.duration;
        let metadata = newAlert.metadata ? newAlert.metadata : {};

        if (message && type) {
          this.$toasts.base('', {
            message: message,
            type: type,
            duration: duration,
            metadata: metadata
          });
          this.lastMessage = message;
        } else {
          // TODO: dispatch a clear event.
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
body {
  background-color: black;
}
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: black;
  }
}
</style>
