<template>
  <div class="aboutSection">
    <div class="gradient"></div>
    <animated-component>
      <div class="about">
        <div class="header">
          <img src="../../../images/apes/ape-head.svg" />
          <h1>ABOUT</h1>
        </div>
        <div class="subtext">
          askldjfa ksdf aksljfaslkj faklsjdf kasldfj aslfdj asldfk aslkdfjas
          lkdfaks dfklasjf alskjf alskjdf alksdfja lsdflaks dflkasdf klasjdf
          alsdflaks dfklasdf jaslkdfj aslkdfjaskldf aslkdfja sldfasl kdfklasjd
          falksdj faksdfj aksldfj kasldfjasdf laksdf asdfjal sdflaksdf klasjdfkl
          asldkfjasfd asfd.
        </div>
      </div>
    </animated-component>

    <div class="slider">
      <ImageSlider />
    </div>
    <div class="gradientBottom"></div>
  </div>
</template>
<script>
// Imports.
import ImageSlider from './ImageSlider.vue';

export default {
  components: { ImageSlider },

  data() {
    return {};
  }
};
</script>
<style scoped lang="scss">
.aboutSection {
  background-color: #0c0f15;
  color: #fff;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 810px;
  position: relative;

  .gradient {
    background: linear-gradient(#0c0f15 10%, rgba(0, 0, 0, 0));
    position: absolute;
    width: 100%;
    height: 120px;
    top: 0;
  }

  .gradientBottom {
    background: linear-gradient(rgba(0, 0, 0, 0) 10%, #0c0f15);
    position: absolute;
    width: 100%;
    height: 120px;
    bottom: 0;
  }

  .about {
    padding: 20px;
    max-width: 30ch;
    font-size: 28px;
    margin-left: auto;
    margin-right: auto;

    .header {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        height: 40px;
      }

      h1 {
        font-size: 25px;
      }
    }
    .subtext {
      font-size: 16px;
    }
  }

  .images {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 400px;
    gap: 10px;
    position: relative;

    .background {
      transform-style: preserve-3d;
      overflow: hidden;
      will-change: transform;
      position: absolute;
      height: 100vh;
      animation: slideshow 40s linear infinite;

      .image {
        width: 100vw;
        height: 100%;
        overflow: hidden;
        @keyframes slideshow {
          0% {
            top: 0;
          }
          100% {
            top: -200%;
          }
        }
      }
    }

    .imageColumn {
      display: flex;
      flex-direction: column;
      gap: 10px;
      transform-style: preserve-3d;
      overflow: hidden;
      will-change: transform;
      position: absolute;
      height: 100vh;
      animation: slideshow 40s linear infinite;

      img {
        width: 100vw;
        height: 200px;
        overflow: hidden;
        @keyframes slideshow {
          0% {
            top: 0;
          }
          100% {
            top: -100%;
          }
        }
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }

    .imageColumnRight {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 100px;

      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutSection {
    grid-template-columns: 1fr;

    .slider {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 800px) {
  .aboutSection .swiper {
    margin-left: -40vw;
  }
}

@media screen and (max-width: 479px) {
  .swiper-slide {
    max-width: 75%;
  }

  .aboutSection .swiper {
    margin-left: -52vw;
  }

  .aboutSection .swiper .picture img {
    width: auto;
    max-width: 70vw;
  }
}
</style>
