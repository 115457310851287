<template>
  <div class="imagesSection">
    <!-- <div class="header">
      <img src="../../../images/apes/ape-head.svg" />
      <h1>ROADMAP</h1>
    </div> -->
    <div class="top">
      <animated-component animationType="left" class="one">
        <img class="one" src="../../../images/apes/comics/1.png" />
      </animated-component>

      <animated-component animationType="right" class="two">
        <img class="two" src="../../../images/apes/comics/2.png" />
      </animated-component>
    </div>

    <div class="middle">
      <animated-component>
        <div class="three">
          <img class="three" src="../../../images/apes/comics/3.png" />
        </div>
      </animated-component>
    </div>

    <div class="middle2">
      <animated-component>
        <div class="four">
          <img class="four" src="../../../images/apes/comics/4.png" />
        </div>
      </animated-component>
    </div>
    <animated-component>
      <div class="bottom">
        <div class="five">
          <img class="five" src="../../../images/apes/comics/5.png" />
        </div>

        <div class="six">
          <img class="six" src="../../../images/apes/comics/6.png" />
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script></script>
<style scoped lang="scss">
.imagesSection {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      height: 40px;
    }

    h1 {
      font-size: 25px;
    }
  }
  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: -40px;
    max-width: 1100px;

    .one {
      max-width: 300px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .two {
      // margin-top: -50px;
      max-width: 650px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .middle {
    margin-top: -10%;
    margin-left: 200px;
    .three {
      max-width: 700px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .middle2 {
    margin-left: 50%;
    margin-right: 10%;
    .four {
      max-width: 500px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .bottom {
    margin-top: -20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .five {
      max-width: 300px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .six {
      max-width: 900px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 800px) {
  .imagesSection {
    .top {
      margin-top: 0px;
    }
    .middle {
      margin-left: 10px;
    }

    .middle2 {
      margin-left: 10%;
    }
    .bottom {
      margin-top: 0px;
      flex-direction: column;
    }
  }
}
</style>
