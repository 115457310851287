<template>
  <div>
    <SplashArea id="splash" />
    <AboutSection id="about" />
    <ImagesSection id="roadmap" />
    <FAQSection id="faqs" />
    <TeamSection id="team" />
    <BottomSection id="bottom" />
    <Footer />
  </div>
</template>
<script>
import SplashArea from './components/SplashArea.vue';
import AboutSection from './components/AboutSection.vue';
import FAQSection from './components/FAQSection.vue';
import Footer from '../layout/Footer.vue';
import ImagesSection from './components/ImagesSection.vue';
import TeamSection from './components/TeamSection.vue';
import BottomSection from './components/BottomSection.vue';

export default {
  components: {
    SplashArea,
    AboutSection,
    ImagesSection,
    FAQSection,
    TeamSection,
    BottomSection,
    Footer
  }
};
</script>
<style scoped lang="scss"></style>
