<template>
  <Ethereum :callback="initialize" />
  <div class="confirmBurn">
    <div class="container">
      <div class="descriptionWrapper">
        <div class="title">Confirm Re-roll</div>
        <div>Please confirm you want to re-roll these Iron Bulls.</div>
      </div>

      <div class="itemsGrid">
        <NFTCard
          :fren="fren"
          v-for="fren in selectedFrens"
          :key="fren.id"
          :class="fren.isSelected ? 'selected' : ''"
        />
      </div>
      <div class="burnButtonWrapper">
        <Button @click="confirmClaim">
          Confirm Re-roll {{ selectedFrens.length }}
        </Button>
        <div class="cancel" @click="router.go(-1)">Cancel</div>
      </div>
    </div>
  </div>
</template>
<script>
// Imports.
import { ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

// Component imports.
import NFTCard from '../my-frens/components/NFTCard.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import Button from '/src/components/ui/Button.vue';

// Icon imports.

export default {
  components: {
    NFTCard,
    Ethereum,
    Button
  },

  props: ['frens'],

  setup(props) {
    const store = useStore();
    const selected = ref();
    const router = useRouter();

    const loading = ref(false);
    if (store.state.mint.frens.length === 0) loading.value = true;
    // Once the state variable changes, the watch is triggered.
    watch(
      () => store.state.mint.frens,
      selection => {
        loading.value = false;
      }
    );

    // Retrieve the the user's owned frens.
    const initialize = () => {
      const address = store.state.ethers.address;
      store.dispatch(
        'mint/getFrens',
        {
          address,
          collectionAddress: '0x71B11Ac923C967CD5998F23F6dae0d779A6ac8Af',
          groupId: 1
        },
        { root: true }
      );
    };

    const selectedFrens = computed(() => {
      let frenIds = new Set(props.frens);

      return store.state.mint.frens.filter(fren => frenIds.has(fren.id));
    });

    const confirmClaim = () => {
      let ids = [];
      let groupOut = 3;
      for (let i = 0; i < selectedFrens.value.length; i++) {
        ids.push('0x' + selectedFrens.value[i].id);
      }
      store.dispatch('burn/reroll', { ids }, { root: true });
    };

    return {
      store,
      initialize,
      loading,
      selected,
      selectedFrens,
      confirmClaim,
      router
    };
  }
};
</script>
<style scoped lang="scss">
.confirmBurn {
  padding: 60px 20px;
  color: white;

  .container {
    width: 1800px;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;

    .descriptionWrapper {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        margin-top: 0px;
        margin-bottom: 20px;
        font-family: Montserrat, sans-serif;
        color: #ffd915;
        font-size: 90px;
        line-height: 1em;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .itemsGrid {
      display: grid;
      width: 100%;
      justify-content: center;
      justify-items: center;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(300px, min-content));
      grid-template-rows: 1fr;
    }

    .burnButtonWrapper {
      position: sticky;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 5;
      display: grid;
      min-height: 60px;
      margin-bottom: 40px;
      padding: 60px 30px;
      justify-content: center;
      justify-items: center;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-columns: auto;
      grid-template-rows: auto;
      background-image: linear-gradient(0deg, #000 50%, transparent);

      .cancel {
        transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #fff;
        opacity: 0.6;
        font-size: 20px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 1;
        }
      }
    }
  }
}
</style>
