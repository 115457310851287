// Imports.
import { createRouter, createWebHistory } from 'vue-router';

// Page imports.
import Home from '../pages/home/Home.vue';
import Mint from '../pages/mint/Mint.vue';
import Asset from '../pages/asset/Asset.vue';
import Burn from '../pages/burn/Burn.vue';
import Collection from '../pages/collection/Collection.vue';
import Sweep from '../pages/sweep/Sweep.vue';
import IronBullRoll from '../pages/iron-bull-roll/IronBullRoll.vue';
import ConfirmBurn from '../pages/confirm-burn/ConfirmBurn.vue';
import ConfirmClaim from '../pages/confirm-claim/ConfirmClaim.vue';
import ConfirmIronBullRoll from '../pages/confirm-iron-bull-roll/ConfirmIronBullRoll.vue';
import MyFrens from '../pages/my-frens/MyFrens.vue';
import Terms from '../pages/terms/Terms.vue';
import NotFound from '../pages/not-found/NotFound.vue';
import Claim from '../pages/claim/Claim.vue';
import Provenance from '../pages/provenance/Provenance.vue';
import RickstroProvenance from '../pages/provenance-rickstro/Provenance.vue';
import RedBullProvenance from '../pages/provenance-red-bull/Provenance.vue';
import SilverBullProvenance from '../pages/provenance-silver-bull/Provenance.vue';

// Create routes.
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/asset',
    name: 'Asset',
    component: Asset,
    props: route => ({ ...route.query })
  },
  {
    path: '/burn',
    name: 'Burn',
    component: Burn
  },
  {
    path: '/collection',
    name: 'Collection',
    component: Collection
  },
  {
    path: '/sweep',
    name: 'Sweep',
    component: Sweep
  },
  {
    path: '/confirm-burn',
    name: 'Confirm Burn',
    component: ConfirmBurn,
    props: route => ({ ...route.params })
  },
  {
    path: '/claim',
    name: 'Claim',
    component: Claim
  },
  {
    path: '/confirm-claim',
    name: 'Confirm Claim',
    component: ConfirmClaim,
    props: route => ({ ...route.params })
  },
  {
    path: '/iron-bull-roll',
    name: 'Iron Bull Roll',
    component: IronBullRoll
  },
  {
    path: '/confirm-iron-bull-roll',
    name: 'Confirm Iron Bull Roll',
    component: ConfirmIronBullRoll,
    props: route => ({ ...route.params })
  },
  {
    path: '/mint',
    name: 'Mint',
    component: Mint
  },
  {
    path: '/my-frens/:id',
    name: 'My Frens',
    component: MyFrens
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/provenance',
    name: 'Provenance',
    component: Provenance
  },
  {
    path: '/provenance-rickstro',
    name: 'Rickstro Provenance',
    component: RickstroProvenance
  },
  {
    path: '/provenance-red-bull',
    name: 'Red IronBull Provenance',
    component: RedBullProvenance
  },
  {
    path: '/provenance-silver-bull',
    name: 'Silver IronBull Provenance',
    component: SilverBullProvenance
  },
  { path: '/:pathMatch(.*)*', component: NotFound }
];

// Set up the Vue router to operate in web history mode.
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
