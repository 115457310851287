<template>
  <div class="bottomSection">
    <div class="join">
      <div class="header">
        <img src="../../../images/apes/ape-head.svg" />
        <p>JOIN US</p>
      </div>

      <div class="text">Jump in to discord and be apart of the gang!</div>
      <button @click="openUrl('')">Join Discord</button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    openUrl(url) {
      window.open(url, '_blank');
    }
  }
};
</script>
<style scoped lang="scss">
.bottomSection {
  background-image: url('../../../images/apes/footer-background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;

  .join {
    background-color: rgba($color: #000000, $alpha: 0.8);
    padding: 60px;
    width: 300px;
    margin-left: auto;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .header {
      display: flex;
      align-items: center;
      img {
        height: 35px;
        width: 35px;
        margin-right: 10px;
      }
      p {
        font-size: 30px;
        margin: 0;
        font-weight: bold;
      }
    }

    .text {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    button {
      border-radius: 5px;
      background-color: rgb(76, 65, 225);
      color: white;
      outline: none;
      border: none;
      padding: 10px;
      width: 80%;
    }
  }
}
</style>
