<template>
  <div class="mint">
    <img
      src="https://astrofrens-metadata.s3.amazonaws.com/assets/mint-bull.png"
      loading="lazy"
      alt="Astro Frens Bull"
      class="bullImage"
    />
    <div class="heading">Mint a Fren</div>
    <div class="total">( 10,000 Astro Bulls Total )</div>
    <div class="subtitle">You can mint a fren with ETH:</div>
    <div v-if="isConnected && isLive" class="mintGrid">
      <MintCard currency="eth" :remaining="0" />
    </div>
    <div v-if="!isLive" class="mintGrid">
      Minting is not live yet!
    </div>
    <div v-if="!isConnected">
      Please connect your wallet.
    </div>
  </div>
</template>
<script>
// Imports.
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// Component imports.
import MintCard from './components/MintCard.vue';

export default {
  components: {
    MintCard
  },

  setup() {
    const store = useStore();

    onMounted(() => {
      window.scroll(0, 0);
    });

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    const isLive = computed(() => {
      return store.state.mint.startDate < new Date();
    });

    return { isConnected, isLive };
  }
};
</script>
<style scoped lang="scss">
.mint {
  padding: 60px 20px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  .bullImage {
    width: 240px;
    margin-bottom: 20px;
    border-radius: 50%;
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: Montserrat, sans-serif;
    font-size: 90px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .total {
    margin-bottom: 20px;
    font-weight: 700;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .mintGrid {
    display: grid;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>
