<template>
  <div class="teamSection">
    <animated-component>
      <div class="header">
        <img src="../../../images/apes/ape-head.svg" />
        <div class="title">TEAM</div>
      </div>
    </animated-component>

    <animated-component>
      <div class="teamContainer">
        <div class="memberWrapper">
          <div class="imageWrapper">
            <img src="../../../images/apes/team-member.png" />
          </div>

          <p class="name">Jame Deo</p>
          <p class="role">Co-Founder</p>
          <p class="description">
            asdf sdf asd fas dfas dfa sdfasd fas dfas dfas d
          </p>
        </div>

        <div class="memberWrapper">
          <div class="imageWrapper">
            <img src="../../../images/apes/team-member.png" />
          </div>

          <p class="name">Jame Deo</p>
          <p class="role">Co-Founder</p>
          <p class="description">
            asdf sdf asd fas dfas dfa sdfasd fas dfas dfas d
          </p>
        </div>
        <div class="memberWrapper">
          <div class="imageWrapper">
            <img src="../../../images/apes/team-member.png" />
          </div>

          <p class="name">Jame Deo</p>
          <p class="role">Co-Founder</p>
          <p class="description">
            asdf sdf asd fas dfas dfa sdfasd fas dfas dfas d
          </p>
        </div>
        <div class="memberWrapper">
          <div class="imageWrapper">
            <img src="../../../images/apes/team-member.png" />
          </div>

          <p class="name">Jame Deo</p>
          <p class="role">Co-Founder</p>
          <p class="description">
            asdf sdf asd fas dfas dfa sdfasd fas dfas dfas d
          </p>
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.teamSection {
  background-image: url('../../../images/apes/apes-bg.png');
  background-position: center;
  background-size: contain;
  padding: 100px 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
    img {
      height: 35px;
      width: 35px;
      margin-right: 10px;
    }

    .title {
      font-weight: bold;
      font-size: 30px;
    }
  }

  .teamContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    .memberWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .imageWrapper {
        max-width: 250px;
        img {
          width: 100%;
          object-fit: contain;
        }
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }

      .name {
        font-weight: 600;
      }

      .role {
        font-size: 14px;
        opacity: 0.5;
      }

      .description {
        font-size: 12px;
        opacity: 0.8;
        max-width: 80%;
      }
    }
  }
}
</style>
