<template>
  <div class="terms">
    <h1>Astro Bulls Provenance Record</h1>
    <div class="section">
      <h2>
        Summary
      </h2>

      <div class="center">
        <div class="bold">
          The Astro Frens smart contract address is:
        </div>
        <a
          href="https://etherscan.io/address/0x71b11ac923c967cd5998f23f6dae0d779a6ac8af/advanced#code"
          >0x71b11ac923c967cd5998f23f6dae0d779a6ac8af</a
        >
      </div>

      <div class="center">
        <div class="bold">
          The offset randomization smart contract address is:
        </div>

        <a
          href="https://etherscan.io/address/0xd2a2708d9b5496d0d00da92b9de905a9af576dec#code"
        >
          0xd2a2708d9b5496d0d00da92b9de905a9af576dec
        </a>
      </div>

      <div class="center">
        <div class="bold">
          The final proof hash is:
        </div>
        <div class="hash">
          {{ provenance.finalProofHash }}
        </div>
      </div>

      <div class="center">
        <div class="bold">
          The randomized offset value is:
        </div>
        <div class="hash">
          9699
        </div>
      </div>
    </div>
    <div class="section">
      <h2>
        What is this page?
      </h2>
      <div class="paragraph">
        This page presents the provenance record of each Astro Bull that will
        ever exist. The purpose of this page is to prove that Astro Bull images
        were all fairly assigned. The following provenance process is a common
        technique among generative NFT projects. First, we generated a SHA-256
        hash of each Astro Bull image. Then we created a combined hash string by
        concatenating each hash for each Astro Bull image in order by their
        initial sequence index. The final proof hash is obtained by generating a
        SHA-256 hash of this combined string. Images are then randomized by
        using Chainlink to roll an offset number.
      </div>
    </div>
    <div class="section">
      <h2>
        What is the offset number?
      </h2>
      <h3>
        The offset number is `9699`. It was rolled from Chainlink. The source of
        randomness is verifiable in
        <a
          href="https://etherscan.io/address/0xd2a2708d9b5496d0d00da92b9de905a9af576dec#readContract"
          >our randomness contract</a
        >
        under the ID
        "0xBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEFBEEF".
      </h3>
      <div class="paragraph">
        The offset number is used to fairly shift all assigned images. Each
        Astro Bull is assigned an artwork image based on its initial sequence
        plus the offset number. If the sum exceeds the number of Astro Bulls, it
        wraps back around. Using an offset number like this removes any
        possibility of the reveal being unfair or biased towards certain
        wallets.
      </div>
    </div>
    <div class="section">
      <h2>
        What is the combined hash string?
      </h2>
      <textarea
        readonly
        v-model="provenance.appendedHash"
        style="background:black;"
      >
      </textarea>
      <div class="paragraph">
        This string is created by concatenating all of the Astro Bull images in
        their initial order, based on their inital index ID before the Chainlink
        random offset is added. The final hash of this offset string is what
        determines the final proof ID. This string is used to verify that the
        initial ordering presented on this provenance page is in fact the
        initial ordering that was used before the Chainlink roll to determine
        the final art assigned to each bull.
      </div>
    </div>
    <div class="section">
      <h2>
        Full Provenance Record
      </h2>
      <div class="paragraph">
        The table below lists the initial sequence number, assigned number based
        on offset, SHA-256 image hash, and IPFS link for each Astro Bull.
      </div>
      <div class="grid header">
        <div class="title">
          Initial Sequence #
        </div>
        <div class="title">
          Assigned #
        </div>
        <div class="title">
          SHA-256 Hash
        </div>
        <div class="title">
          IPFS Hash
        </div>
      </div>
      <ul>
        <li v-for="(item, index) in provenance.items" :key="index" class="grid">
          <div>
            {{ item.initialId }}
          </div>
          <div>
            {{ item.assignedId }}
          </div>
          <div>
            {{ item.hash }}
          </div>

          <a :href="item.ipfsUrl"> {{ getHashFromUrl(item.ipfsUrl) }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { formatAddress } from '/src/utility/format';
('use strict');

// Import the provenance file.
const provenance = require('./provenance.json');

export default {
  data() {
    return {
      provenance,
      formatAddress
    };
  },
  mounted() {
    window.scroll(0, 0);
  },

  setup() {
    const getHashFromUrl = url => {
      return url.split('https://ipfs.io/ipfs/')[1].split('/media/')[0];
    };

    return {
      getHashFromUrl
    };
  }
};
</script>

<style scoped lang="scss">
.terms {
  padding: 60px;
  max-width: 1600px;
  margin: auto;
  color: white;
  h1 {
    font-size: 40px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .section {
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;

    h2 {
      text-align: left;
      font-size: 30px;
    }

    .important {
      font-weight: bold;
      color: red;
      font-size: 25px;
      text-align: left;
      font-style: italic;
    }

    .center {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 10px;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
      .bold {
        font-weight: bold;
      }

      a {
        color: #ffd915;
        font-weight: bold;
      }

      .hash {
        font-weight: bold;
      }
    }

    .rolled {
      font-size: 20px;
      opacity: 0.7;
      font-style: italic;
    }

    .emoji {
      font-size: 40px;
      padding-top: 10px;
    }

    .paragraph {
      text-align: left;
    }

    textarea {
      width: 100%;
      height: 100px;
      opacity: 0.7;
      margin-bottom: 40px;
    }

    .grid {
      display: grid;
      grid-template-columns: 150px 150px 1fr 1fr;
      text-align: left;
      width: 100%;
      font-size: 12px;

      a {
        font-weight: bold;
        color: #ffd915;
      }

      .title {
        font-size: 15px;
        font-weight: bold;
      }
    }

    .header {
      border-bottom: 1px solid rgba(255, 255, 255, 1);
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
</style>
