<template>
  <Ethereum :callback="initialize" />
  <div class="mintCard">
    <EthIcon v-if="currency === 'eth'" class="currencyLogo" />
    <SuperIcon v-else class="currencyLogo" />
    <div>
      <form class="form">
        <div>Mint</div>
        <select class="select" v-model="quantitySelected">
          <option class="option" v-for="i in 15" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
        <div>Fren</div>
      </form>
    </div>
    <Button v-if="quantityRemaining > 0" @click.stop="triggerMint">
      Mint {{ quantitySelected }} {{ frenLabel }} for {{ pricePerNFT }}
      {{ currency }}
    </Button>
    <div class="soldOut" v-if="quantityRemaining === 0">
      SOLD OUT
    </div>
    <div class="text-block-4">{{ quantityRemaining }} remaining</div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

// Component imports.
import Button from '/src/components/ui/Button';
import Ethereum from '/src/components/common/Ethereum.vue';

// Icon imports.
import EthIcon from '/src/components/icons/EthIcon';
import SuperIcon from '/src/components/icons/SuperIcon';

export default {
  components: {
    Button,
    EthIcon,
    SuperIcon,
    Ethereum
  },

  props: {
    currency: String
  },

  setup(props) {
    const store = useStore();

    const quantitySelected = ref(1);

    const frenLabel = computed(() => {
      return quantitySelected.value > 1 ? 'frens' : 'fren';
    });

    const pricePerNFT = computed(() => {
      return props.currency === 'eth' ? 0.08 * quantitySelected.value : 'xxx';
    });

    const quantityRemaining = computed(() => {
      if (store.state.mint.shop) {
        let item = store.state.mint.shop.pools[0].items[0];
        return item.cap.toNumber() - item.minted.toNumber();
      }
      return -1;
    });

    const triggerMint = function() {
      console.log(`Attempting to purchase ${quantitySelected.value} frens ...`);
      store.dispatch(
        'mint/purchaseItem',
        {
          shopAddress: store.state.mint.shopAddress,
          poolId: 0,
          groupId: 1,
          assetId: 0,
          amount: quantitySelected.value
        },
        { root: true }
      );
    };

    const initialize = () => {
      // Retrieve the number of bulls from the store.
      store.dispatch('mint/getShopItems', store.state.mint.shopAddress, {
        root: true
      });

      // Poll remaining bulls from the store.
      setInterval(() => {
        store.dispatch('mint/getShopItems', store.state.mint.shopAddress, {
          root: true
        });
      }, 15000);
    };

    return {
      quantitySelected,
      pricePerNFT,
      quantityRemaining,
      triggerMint,
      frenLabel,
      initialize
    };
  }
};
</script>
<style scoped lang="scss">
.mintCard {
  display: grid;
  padding: 20px;
  justify-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.2);
  border-radius: 30px;
  text-align: center;

  .currencyLogo {
    width: 40px;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
  }

  .form {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    font-size: 24px;

    .select {
      width: 80px;
      margin: 0px 10px;
      margin-bottom: 0px;
      padding: 8px 15px;
      border-style: solid;
      border-width: 1px;
      border-color: #fff;
      border-radius: 30px;
      background-color: #000;
      background-image: url('../../../images/down-arrow.svg');
      background-position: 80% 50%;
      background-size: auto;
      background-repeat: no-repeat;
      transition: border-color 200ms ease;
      color: #fff;
      font-weight: 700;
      appearance: none;
      &:hover {
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }

      &:focus {
        border-width: 2px;
        outline: none;
      }

      .option {
        color: black;
        background-color: white;
      }
    }
  }

  .soldOut {
    background-color: red;
    padding: 20px;
  }
}
</style>
