<template>
  <div id="container">
    <!-- Each image is 350px by 233px -->
    <div class="photobanner">
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
    </div>

    <div class="photobanner offset">
      <img class="offset" src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
      <img src="../../../images/apes/sample-ape.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

#container {
  height: 100vh;

  overflow: hidden;
  display: flex;
  gap: 10px;
}

/*photobanner*/

.photobanner {
  width: 250px;
  height: 2500;
  animation: bannermove 30s linear infinite;
}

.offset {
  margin-top: -125px;
}

img {
  height: 250px;
  width: 250px;
}

@keyframes bannermove {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: -1250px;
  }
}

@media (max-width: 800px) {
  #container {
    height: 500px;
    overflow: hidden;
    display: flex;
    gap: 10px;
  }
  .photobanner {
    width: 225px;
  }
}
</style>
