<template>
  <div class="faqSection">
    <div class="imageContainer">
      <!--<img src="../../../images/apes/rotating-head.gif" />-->
    </div>
    <div class="container">
      <animated-component>
        <div class="heading">
          <img src="../../../images/apes/ape-head.svg" />
          <div>FAQ</div>
        </div>
      </animated-component>
      <div class="dropdownWrapper" v-for="item in faq" :key="item.title">
        <animated-component animationType="right">
          <DropdownItem
            class="faq"
            :title="item.title"
            :description="item.description"
          />
        </animated-component>
      </div>
    </div>
  </div>
</template>
<script>
import DropdownItem from './DropdownItem.vue';
export default {
  components: {
    DropdownItem
  },

  setup() {
    const faq = [
      {
        title: 'Sample Text',
        description: ['Sample answer.']
      },
      {
        title: 'Sample Text',
        description: ['Sample answer.']
      },
      {
        title: 'Sample Text',
        description: ['Sample answer.']
      },
      {
        title: 'Sample Text',
        description: ['Sample answer.']
      },
      {
        title: 'Sample Text',
        description: ['Sample answer.']
      },
      {
        title: 'Sample Text',
        description: ['Sample answer.']
      },
      {
        title: 'Sample Text',
        description: ['Sample answer.']
      },
      {
        title: 'Sample Text',
        description: ['Sample answer.']
      }
    ];

    return {
      faq
    };
  }
};
</script>
<style scoped lang="scss">
.faqSection {
  background-color: black;
  max-width: 1600px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 150px 0px;

  .imageContainer {
    max-width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .container {
    padding-right: 20px;

    .heading {
      font-size: 32px;
      font-weight: 700;
      text-transform: uppercase;

      display: flex;
      align-items: center;

      img {
        height: 35px;
        width: 35px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 800px) {
  .faqSection {
    grid-template-columns: 1fr;

    .container {
      padding-left: 20px;

      .heading {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 600px) {
  .faqSection .container .dropdownWrapper .faq {
    margin: 20px 0;
  }
}
</style>
